<div class="error-container">
    <div class="error-inside-container">
        <div class="error-title">
            <div class="logo-container">
                <img [src]="companyLogo" alt="Company Logo" />
            </div>
            <div class="error-text-container">
                <div class="error-status-code">
                    <h1> {{ statusCode }}</h1>
                </div>
                <div class="error-description-container">
                    <p> {{ errorDescription[0] }} </p>
                    <p> {{ errorDescription[1] }} </p>
                </div>
            </div>
            <button type="button" class="homepage-button" (click)="onBackHomepageClick()"> {{ homepageButton }} </button>
        </div>
        <div class="error-image-container">
            <img [src]="errorImage" alt="Error image" />
        </div>
    </div>
</div>
